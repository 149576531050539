<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 14:45:04
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.recruit {
  display: flex;
  flex-direction: column;
  align-items: center;
  .recruit-banner {
    width: 1920px;
    height: 400px;
    background: url("../../assets/images/recruit_banner.jpg");
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      max-width: 1200px;
      font-size: 60px;
      font-weight: 500;
      color: #000;
      text-align: center;
    }
  }
  .exchange-tip {
    width: 1200px;
    min-height: 48px;
    line-height: 26px;
    padding: 20px;
    background-color: #eaf1ff;
    color: #447df5;
    font-size: 16px;
    margin-top: 24px;
    border-radius: 4px;
  }
  .recruit-section {
    min-width: 1200px;
    // width: 1200px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .invitation {
      width: 1200px;
      height: 303px;
      margin-top: 20px;
      box-shadow: 0 0 20px 0 #eef0f7;
      padding: 20px 24px;
      display: flex;
      flex-direction: row;
      .invitation-item {
        // flex: 1;
        display: flex;
        flex-direction: column;
        .invitation-title {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        .invitation-subtitle {
          margin-top: 8px;
          font-size: 14px;
          color: #999999;
        }
        .invitation-url {
          width: 541px;
          height: 65px;
          background: #fcfcfc;
          border: 1px solid #efefef;
          margin-top: 18px;
          padding: 0 20px;
          line-height: 65px;
          font-size: 18px;
          color: #666666;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .copy-btn {
          margin-top: 18px;
          width: 541px;
          height: 68px;
          line-height: 68px;
          text-align: center;
          background-color: #447df5;
          border-radius: 4px;
          font-size: 24px;
          color: #ffffff;
          font-weight: bold;
        }
      }
      .qrcode {
        margin-left: 98px;
        position: relative;
        .qrcode-ico {
          width: 137px;
          height: 137px;
          margin-left: 93px;
          margin-top: 52px;
          display: inline-block;
          img {
            background-color: #fff; //设置白色背景色
            padding: 6px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
          }
        }
        .download {
          width: 132px;
          height: 36px;
          background-image: url("../../assets/images/download_qrcode.png");
          background-repeat: no-repeat;
          background-size: 132px 36px;
          position: absolute;
          left: 260px;
          top: 160px;
        }
      }
    }
    .user-invite {
      min-width: 1200px;
      height: 538px;
      margin-top: 20px;
      box-shadow: 0 0 20px 0 #eef0f7;
      padding: 20px 24px;
      display: flex;
      flex-direction: row;
      .invite-person-data {
        width: 605px;
      }
      .invite-rank {
        width: 502px;
        margin-left: 33px;
      }
      .user-invite-item {
        display: flex;
        flex-direction: column;
        > .title {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        .data-cnt {
          margin-top: 13px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .cnt-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
              font-size: 24px;
              font-weight: bold;
              color: #447df5;
            }
            h4 {
              margin-top: 8px;
              font-size: 16px;
              color: #666666;
            }
          }
        }
        .data-table {
          width: 100%;
          margin-top: 26px;
          border: 1px solid #e9e9e9;
          .data-table-lable {
            width: 100%;
            height: 40px;
            line-height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #e9e9e9;
            .lable-item {
              flex: 1;
              font-size: 14px;
              color: #666666;
              text-align: center;
              line-height: 13px;
            }
          }
          .no-data {
            width: 100%;
            padding: 0 14px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #999999;
            border-bottom: 1px solid #e9e9e9;
          }
          .data-table-footer {
            width: 100%;
            padding: 0 14px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #999999;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            span {
              margin-left: 14px;
            }
          }
        }
      }
    }
    .invite-rule {
      min-width: 1200px;
      margin-top: 20px;
      padding: 20px 24px;
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        .color-447df5();
      }
      p {
        font-size: 14px;
        color: #333333;
        line-height: 30px;
      }
    }
  }
}
.table {
  width: 100%;
  height: 40px;
}
.line {
  width: 1px;
  height: 18px;
  border-left: 1px solid #e7e7e7;
}
.color-447df5 {
  color: #447df5;
}
.bg-f4f4f4 {
  background: #f4f4f4;
}
</style>
<template>
  <basic-layout>
    <div class="recruit">
      <div class="recruit-banner">
        <p class="title">如果觉得朋友还不错，请把蚂蚁调研网告诉TA！</p>
      </div>
      <!-- <img
        class="recruit-banner"
        src="@assets/images/recruit_banner.jpg"
        alt=""
      /> -->
      <div class="exchange-tip">
        推荐朋友加入蚂蚁调研网的规则说明：
        <br>1、通过推广链接、二维码等方式邀请朋友注册蚂蚁调研网。
        <br>2、您推荐的朋友注册后成功完成首份调查问卷，您将额外立即获得一次性的200金币（2元）奖励。
        <br>3、推荐人可以获得被推荐人成功回答问卷奖励金额的10%作为推荐奖励（由平台支付，不会扣除被推荐人的奖励）。例如：被推荐人在某月完成问卷的奖励（100元），推荐人可以获得10%（即10元）的奖励。
        <br>4、由平台系统自动计算记录推荐奖励金币并计入总计金币。推荐人可以在兑换中心进行提现。
        <br>5、推荐奖励计算的有效期，为被推荐人注册后的365天内完成的成功问卷。
        <br>6、如果被推荐人超过6个月没有参与任何问卷，为避免资源闲置，被推荐人账户将会视为休眠用户，并与您断开推荐关系，推荐人将无法获得推荐奖励。
      </div>
      <div class="recruit-section">
        <!-- 邀请方式 -->
        <div class="invitation">
          <div class="invitation-item">
            <p class="invitation-title">邀请方式一：</p>
            <p class="invitation-subtitle">
              复制下面链接发给您的朋友，朋友通过此链接注册 <br />
              为会员并且邮件激活且完成一份商业调查后，即为成功推荐一名会员。
            </p>
            <div class="invitation-url">
              {{ invitationUrl }}
            </div>
            <div
              class="copy-btn"
              v-clipboard:copy="invitationUrl"
              v-clipboard:success="copySuccess"
            >
              一键复制
            </div>
          </div>
          <div class="invitation-item qrcode">
            <p class="invitation-title">邀请方式二：直接分享</p>
            <p class="invitation-subtitle">
              将我们为您生成的二维码发给朋友同样可以邀请
            </p>
            <div ref="qrCodeUrl" class="qrcode-ico"></div>
            <div class="download" @click="handleDownloadQrImg"/>
          </div>
        </div>
        <div class="user-invite">
          <!-- 我的邀请 -->
          <div class="invite-person-data user-invite-item">
            <div class="title">我的邀请</div>
            <div class="data-cnt">
              <div class="cnt-item">
                <h3>{{ inviteeCount }}人</h3>
                <h4>已邀请好友</h4>
              </div>
              <div class="cnt-item">
                <h3>{{ priceCount }}金币</h3>
                <h4>已获得奖励</h4>
              </div>
            </div>
            <div class="data-table">
              <div class="data-table-lable bg-f4f4f4">
                <div class="lable-item">好友账号</div>
                <div class="line"></div>
                <div class="lable-item">注册时间</div>
                <div class="line"></div>
                <div class="lable-item">所赚金币</div>
                <div class="line"></div>
                <div class="lable-item">废卷率</div>
                <div class="line"></div>
                <div class="lable-item">登录时间</div>
              </div>
              <div
                :class="['data-table-lable', (index + 1) % 2 === 0 ? 'bg-f4f4f4' : '']"
                v-for="(item, index) in inviteelist"
                :key="item.member_id"
              >
                <div class="lable-item">{{ item.member_name }}</div>
                <div class="line"></div>
                <div class="lable-item">{{ item.member_regist_time | format }}</div>
                <div class="line"></div>
                <div class="lable-item">{{ item.answer_coin }}</div>
                <div class="line"></div>
                <div class="lable-item">{{ item.invalid_question_ratio/100 }}%</div>
                <div class="line"></div>
                <div class="lable-item" style="width:200px">{{ item.last_login_time | format }}</div>
              </div>
              <div class="no-data" v-if="inviteeCount==0">您还没邀请记录…</div>
              <div class="data-table-footer">
                <div>第{{ page }}/{{ pageCount }}页 共{{ inviteeCount }}条</div>
                <div>
                  <span :class="{'color-447df5': beforePage}" @click="beforeClick">上一页</span><span :class="{'color-447df5': nextPage}" @click="nextClick">下一页</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 推荐排行榜 -->
          <div class="invite-rank user-invite-item">
            <div class="title">推荐排行榜</div>
            <div class="data-table">
              <div class="data-table-lable bg-f4f4f4">
                <div class="lable-item">排名</div>
                <div class="line"></div>
                <div class="lable-item">用户昵称</div>
                <div class="line"></div>
                <div class="lable-item">推荐人数</div>
              </div>
              <div
                :class="['data-table-lable', (index + 1) % 2 === 0 ? 'bg-f4f4f4' : '']"
                v-for="(item, index) in statistic_info_list"
                :key="item.member_id"
              >
                <div class="lable-item">{{ index + 1 }}</div>
                <div class="line"></div>
                <div class="lable-item">{{ item.member_name }}</div>
                <div class="line"></div>
                <div class="lable-item">{{ item.invite_member_count }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="invite-rule">
          <h2>活动规则</h2>
          <p>1、通过推广链接、二维码、微信微博方式邀请朋友注册蚂蚁调研网。</p>
          <p>
            2、您的好友第一次完成调查，您将立即获得一次性的<span style="font-weight: 500; color: #0A1C39;">2元推荐奖励</span>，同时您的好友今后每完成一次调查，<span style="font-weight: 500; color: #0A1C39;">您都可获得20%的提成</span>。列如：您推荐好友“小调”注册蚂蚁调研网，“小调”第一次完成一份30元的调查，您可以获得2元推荐奖励+6元的提成奖励。
          </p>
          <p>
            3、如果您的好友超过6个月没有参与任何问卷，为避免资源闲置，账户将会视为休眠用户，并与您断开好友关系，您也无法获得奖励。
          </p>
          <p>4、活动日期：<span style="font-weight: 500; color: #0A1C39;">2020-1-1 至 2020-12-31。</span></p>
          <p>注意：活动期间内奖励翻倍，活动结束后奖励恢复正常</p>
        </div> -->
      </div>
    </div>
  </basic-layout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import { message } from 'ant-design-vue';
import QRCode from 'qrcodejs2'
import { userInviteelist } from "@/api/user.js"
import { fetchInviterankinglist } from "@/api/base.js"
import { timeFormatToYMDFilter } from "@/utils/filters";

export default {
  name: "recruit",
  components: {
    BasicLayout,
  },
  data() {
    return {
      invitationUrl: "",
      beforePage: false,
      nextPage: true,
      inviteeCount: 0,
      priceCount: 0,
      pageCount: 0,
      inviteelist: [],
      page_size: 7,
      page: 1,
      statistic_info_list: () => [],
    };
  },
  filters: {
    format(value) {
      return timeFormatToYMDFilter(value, "YY-MM-DD")
    }
  },
  created() {
    this.invitationUrl = window.location.origin + '?inviter=' + this.userId
    console.log(this.invitationUrl)
    this.initData();
  },
  mounted() {
    this.creatQrCode()
  },
  methods: {
    initData() {
      this.fetchInviteelist()
      this.fetchInviteranking()
    },
    /// 获取排行
    async fetchInviteranking() {
      try {
        let result = await fetchInviterankinglist({
          page_size: 10,
        });  
        this.statistic_info_list = result.statistic_info_list;
      } catch (error) {
        console.log(error);
      }
    },
    /// 获取记录列表
    async fetchInviteelist() {
      try {
        let result = await userInviteelist({
          member_id: this.userId,
          token: this.userToken, 
          page_size: this.page_size,
          page: this.page
        });  
        this.inviteeCount = result.total
        this.priceCount = result.profit_share_coin
        this.pageCount = Math.floor((result.total + this.page_size - 1) / this.page_size)
        this.inviteelist.push(...result.member_list);
        /// 更新分页状态
        if (this.page <= 1) {
          this.beforePage = false;
        }
        if (this.pageCount <= this.page) {
          this.nextPage = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /// 分页
    beforeClick() {
      if (!this.beforePage) return;
      this.nextPage = true;
      if (this.page <= 1) {
        this.beforePage = false;
      }
      this.page--;
      this.fetchInviteelist()
    },
    nextClick() {
      if (!this.nextPage) return;
      this.beforePage = true;
      if (this.page >= this.pageCount ) {
        this.nextPage = false;
      }
      this.page++;
      this.fetchInviteelist()
    },
    /// 赋值用户邀请链接
    copySuccess() {
      message.info('复制成功！！！');
    },
    /// 生成二维码
    creatQrCode() {
      var that = this;
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: that.invitationUrl, // 需要转换为二维码的内容
        width: 300,
        height: 300,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 点击下载图片
    handleDownloadQrImg() {
      var canvasData = this.$refs.qrCodeUrl.getElementsByTagName('canvas')
      var a = document.createElement("a");
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.href = canvasData[0].toDataURL("image/png");;
      a.download = "邀请二维码";
      a.dispatchEvent(event); // 触发a的单击事件
    }
  },
};
</script>
